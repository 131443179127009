<template>
    <div>
        <!-- Start 404 Error Area -->
        <section class="error-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="error-content">
                            <div class="notfound-404">
                                <h1>{{ $t('Oops!') }}</h1>
                            </div>
                            <h3>{{ $t('404 - Page not found') }}</h3>
                            <p> {{ $t('The page you are looking for might have been removed had its name changed or is temporarily unavailable.') }}</p>
                            <a href="/" class="btn btn-primary"> {{ $t('Go to Homepage') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End 404 Error Area -->
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>